import { useEffect, useCallback, FunctionComponent } from 'react';
import { Item, Notification } from '~types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useQuery, gql, useMutation, useSubscription } from '@apollo/client';
import { toast, TypeOptions } from 'react-toastify';
import { useQueue, useCookie } from 'react-use';

import { useOpenModal } from '../../components/modals/hooks';
import { SteamItemImage } from '../item';
import Currency from '../Currency';
import { Price } from '~shared/frontend/ui';
import { Link } from 'react-router-dom';
import { useConfig } from '../hooks';

const FRAGMENT_NOTIFICATION_FILEDS = gql`
  fragment NotificationFields on Notification {
    id
    text
    title
    type
    ttl
    props
  }
`;

const GET_NOTIFICATION = gql`
  query Notifications {
    getNotifications {
      ...NotificationFields
    }
  }

  ${FRAGMENT_NOTIFICATION_FILEDS}
`;

interface NotificationsData {
  getNotifications: Notification[];
}

interface NotificationsSubscribeData {
  pushNotification: Notification;
}

interface NotificationsMutationData {
  deleteNotification: Notification;
}

interface NotificationsMutationVars {
  id: number;
}

interface ModalTypes {
  add: any;
  remove: any;
  first: {
    id: number;
    title: string;
    props: any;
  };
}

const Notifications: FunctionComponent = () => {
  const [bloggerAuth] = useCookie('bloggerAuth');
  const getConfig = useConfig();
  const yametrica = useYaMetrica();
  const { onShow } = useOpenModal();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const {
    add: addModal,
    remove: removeModal,
    first: activeModal,
  }: ModalTypes = useQueue();

  const pushNotification = ({
    title,
    type,
    ttl,
    text,
    id,
    props,
  }: Notification) => {
    const getItem = props?.getItem as Item;

    if (_.toInteger(type) === 1) {
      if (bloggerAuth && title.includes('Promo')) {
        return false;
      }
      addModal({ title, props, id });
    } else if (getItem) {
      toast(
        <div id={String(id)} className="notify-product">
          <div className="top-drop-item status-4" data-rar={getItem?.color}>
            <div className="in-case-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getItem?.price} />
                </>
              ) : (
                <>
                  <Price sum={getItem?.price} />
                  <Currency />
                </>
              )}
            </div>
            <div className="honey"></div>
            <div className="drop-img">
              <SteamItemImage src={getItem?.getImage} />
            </div>
            <div className="top-drop-item-hover">
              <div className="top-drop-item-name">
                {_.toString(
                  language === 'ru' ? getItem?.name : getItem?.name_en,
                )}
              </div>
            </div>
          </div>
          <div className="notify-product-content">
            <div className="notify-title">{t('Sold')}!</div>
            <div className="notify-text">
              {_.toString(language === 'ru' ? getItem?.name : getItem?.name_en)}
            </div>
            <div className="notify-price">
              {t('sold for')}{' '}
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getItem?.price} />
                </>
              ) : (
                <>
                  <Price sum={getItem?.price} />
                  <Currency />
                </>
              )}
            </div>
          </div>
        </div>,

        {
          type: type as TypeOptions,
          className: 'notify-sell-product',
          closeButton: (
            <div
              className="close-button icon material-icons"
              data-icon="close"
            />
          ),
          autoClose: ttl < 1 ? 5000 : ttl,
          icon: false,
          onClose: () => {
            onClose(id);
          },
        },
      );
    } else {
      toast(
        props?.type === 'questCompleted' ? (
          <div className="notify-product notify-quest" id={String(id)}>
            <div className="notify-product-content">
              <div
                className="notify-title"
                dangerouslySetInnerHTML={{ __html: t(title) }}
              />
              <div className="notify-quest-wrap">
                <div
                  className="notify-text"
                  dangerouslySetInnerHTML={{ __html: t(text, props) }}
                />
                <div className="notify-quest-price">
                  <span>+{props?.coins}</span>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM8 12.917C10.8377 12.441 13 9.973 13 7C13 4.027 10.8377 1.55904 8 1.08296V4H10L11 5H6L5 6V8L6 9H11L10 10H8V12.917ZM6 12.917V10H5L3 8V6L5 4H6V1.08296C3.16229 1.55904 1 4.027 1 7C1 9.973 3.16229 12.441 6 12.917Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
              </div>
              <Link
                className="notify-btn-go-to"
                to={getConfig.getBattlePass.getUrl}
              >
                {t('Go to battle pass')}
              </Link>
            </div>
          </div>
        ) : (
          <div id={String(id)}>
            <div
              className="notify-title"
              dangerouslySetInnerHTML={{ __html: t(title) }}
            />
            <div
              className="notify-text"
              dangerouslySetInnerHTML={{ __html: t(text, props) }}
            />
            {props?.tradeUrl && (
              <a
                className="notify-btn btn btn_medium btn_green"
                href={props?.tradeUrl}
                target="_blank"
                rel="noreferrer"
              >
                <span className="icon icon-profile"></span>
                {t('Accept')}
              </a>
            )}
          </div>
        ),
        {
          type: type as TypeOptions,
          autoClose: ttl < 1 ? 5000 : ttl,
          className: props?.type === 'questCompleted' && 'notify-sell-product',
          onClose: () => {
            onClose(id);

            if (props?.getPayment) {
              yametrica.sendTransaction({
                id: props?.getPayment?.id,
                revenue: props?.getPayment?.revenue,
                name: `Пополнение баланса`,
                coupon: props?.getPayment?.coupon,
                price: props?.getPayment?.price,
                userId: props?.getPayment?.userId,
                paymentMethodName: props?.getPayment?.paymentMethodName,
              });
            } else if (props?.type === 'questCompleted') {
              yametrica.reachGoal('Quest_battlepass_complete', props);
            }
          },
          //    containerId,
        },
      );
    }
  };

  const { loading, error } = useQuery<NotificationsData>(GET_NOTIFICATION, {
    ssr: false,
    onCompleted: data => {
      const getNotifications = data?.getNotifications || [];

      for (const notification of getNotifications) {
        pushNotification(notification);
      }
    },
  });

  const [deleteNotification] = useMutation<
    NotificationsMutationData,
    NotificationsMutationVars
  >(
    gql`
      mutation deleteNotification($id: Int!) {
        deleteNotification(id: $id) {
          id
        }
      }
    `,
    {
      update(cache, { data: { deleteNotification } }) {
        cache.modify({
          fields: {
            getNotifications(list, { readField }) {
              return list.filter(
                (notification: any) =>
                  readField('id', notification) !== deleteNotification.id,
              );
            },
          },
        });
      },
    },
  );

  const onClose = useCallback(
    async id => {
      await deleteNotification({ variables: { id: _.toInteger(id) } });
    },
    [deleteNotification],
  );

  useSubscription<NotificationsSubscribeData>(
    gql`
      subscription pushNotification {
        pushNotification {
          ...NotificationFields
        }
      }

      ${FRAGMENT_NOTIFICATION_FILEDS}
    `,
    {
      onData: ({ data }) => {
        const notification = data?.data?.pushNotification;

        if (notification) {
          pushNotification(notification);
        }
      },
    },
  );

  useEffect(() => {
    if (activeModal) {
      const onCloseCall = () => {
        onClose(activeModal.id);

        removeModal();
      };

      onShow(activeModal.title, {
        onClose: onCloseCall,
        ...activeModal.props,
      });
    }
  }, [activeModal]);

  if (loading) {
    return null;
  } else if (error) {
    return <>Notifications | Error! {error.message}</>;
  }

  return null;
};

export default Notifications;
