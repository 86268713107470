import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import IconMenu1 from '../../scss/images/icon-menu-1.svg?react';
import IconProfile from '../../scss/images/icon-menu-10.svg?react';
import IconExit from '../../scss/images/icon-menu-11.svg?react';
import IconFaq from '../../scss/images/icon-menu-12.svg?react';
import IconEarn from '../../scss/images/icon-menu-14.svg?react';
import IconSupport from '../../scss/images/icon-menu-18.svg?react';
import IconContracts from '../../scss/images/icon-menu-2.svg?react';
import IconUpgrade from '../../scss/images/icon-menu-4.svg?react';
import { useConfig, useProfile } from '../hooks';

export const Menu = () => {
  const { t } = useTranslation();
  const getProfile = useProfile();
  const getConfig = useConfig();

  const onClick = () => {
    if (window.jivo_api) {
      document.querySelector('jdiv').className = 'active';
      window.jivo_api.open();
    }
  };

  return (
    <>
      {getProfile && (
        <Link className="header-profile-user__dropdown-item" to="/profile">
          <IconProfile />
          <span>{t('Profile')}</span>
        </Link>
      )}
      <a
        href="/referral"
        target="_blank"
        rel="nofollow"
        className="header-profile-user__dropdown-item"
      >
        <IconMenu1 />
        <span>{t('Earn')}</span>
      </a>
      {/* <Link className="header-profile-user__dropdown-item" to="/wheel">
      <IconEarn />
      <span>{t('Distribution')}</span>
    </Link> */}
      <Link className="header-profile-user__dropdown-item" to="/contract">
        <IconContracts />
        <span>{t('Contracts')}</span>
      </Link>
      <Link className="header-profile-user__dropdown-item" to="/upgrade">
        <IconUpgrade />
        <span>{t('Upgrades')}</span>
      </Link>
      <Link className="header-profile-user__dropdown-item" to="/faq">
        <IconFaq />
        <span>{t('Faq')}</span>
      </Link>
      <div className="header-profile-user__dropdown-item" onClick={onClick}>
        <IconSupport />
        <span>{t('support to')}</span>
      </div>
      {getProfile && (
        <a className="header-profile-user__dropdown-item" href="/auth/logout">
          <IconExit />
          <span>{t('logout')}</span>
        </a>
      )}
    </>
  );
};
